<template>
	<div class="container mt-4">
		<h2 class="label-header">Activated Members</h2>
		<div class="card mt-4">
			<div class="card-body">
				<div class="table-responsive">
					<table class="table table-hover table-striped table-bordered">
						<thead>
							<tr>
								<th>Full name</th>
								<th>Email</th>
								<th>Phone</th>
								<th>Payment mode</th>
							</tr>
						</thead>
						<tbody v-if="isLoading">
							<tr>
								<td colspan="4">
									<div class="d-flex justify-content-center flex-column align-items-center">
										<span style="width: 30px; height: 30px;" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>Loading Data
									</div>
								</td>
							</tr>
						</tbody>
						<tbody v-if="!isLoading && memberList.length > 0">
							<tr v-for="(item, i) in memberList" :key="i">
								<td>{{ item.full_name }}</td>
								<td>{{ item.email }}</td>
								<td>{{ item.phone }}</td>
								<td>{{ item.payment }}</td>
							</tr>
						</tbody>
						<tbody v-if="!isLoading && memberList.length === 0">
							<tr>
								<td colspan="4">
									<div class="d-flex justify-content-center flex-column align-items-center">
										No Member have activated yet
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="d-flex justify-content-center flex-column align-items-center" v-if="lastIndex !== 0">
					<button class="btn btn-sm btn-secondary" @click="loadPage()">Load More</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { activationService } from '@/services/activation.service'
export default {
	name: 'ActivatedMember',
	data() {
		return {
			memberList: [],
			isLoading: true,
			lastIndex: 0
		};
	},
	mounted() {
		this.loadPage()
	},
	methods: {
		loadPage () {
			activationService.loadMember(this.lastIndex)
				.then((response) => {
					this.isLoading = false
					if(parseInt(response.data.message_code) === 200){
						for(const item of response.data.memberList){
							this.memberList.push(item)
						}
						this.lastIndex = response.data.lastIndex
					}
				})
				.catch((e) => {
					this.isLoading = false
					console.log(e)
				})
		}
	}
};
</script>

<style scoped>
.container{
	background: var(--paul-color-light);
}
label{
	font-weight: 550;
	font-size: 14px;
	margin-bottom: 5px;
}
</style>
